import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavbarItem = styled(Link)`
  font-family: Kodchasan, serif;
  color: grey;
  font-size: 18px;
  font-weight: bold;
  margin-left: 0px;
  transition: 400ms;
  padding: 12px;
  text-decoration: none;
  margin-right: 10px;
  border-radius: 12px;
  transition: 520ms;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: rgb(22,22,24,0.3);
    transition: 520ms;
  }
  @media screen and (max-width: 720px){
    
  }
`;
export const Navbar = styled.div`
  margin-left: auto;
  

  @media screen and (max-width: 720px) {
    margin: 10px auto;
  }
  
`;