import { HomePage } from "./Pages/HomePage";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { AboutMePage } from "./Pages/AboutMePage";
import { Intro } from "./Pages/IntrosPage";
import { VideoEdit } from "./Pages/VidEditsPage";
import { Graphics } from "./Pages/GraphicsPage";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route path="/aboutme">
          <AboutMePage />
        </Route>
        <Route path="/intros">
          <Intro />
        </Route>
        <Route path="/video-edits">
          <VideoEdit />
        </Route>
        <Route path="/graphics">
          <Graphics />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
