import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navbar, NavbarItem } from "./Navbar";

export const PageHeader = (props) => {
  return (
    <Header>
      <HeaderBackground />
      <Title>
        <MainTitle to="/">Jakub Flotyński</MainTitle>
        <MainSubTitle>'s portfolio</MainSubTitle>
      </Title>
      <Navbar>
        {props.children}
        <NavbarItem to="/">Home</NavbarItem>
        <NavbarItem to="/aboutme">About me</NavbarItem>
      </Navbar>
    </Header>
  );
};

const Header = styled.header`
  background-color: #18181c;
  background-color: transparent;
  z-index: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 36px 50px;
  position: sticky;
  flex-wrap: wrap;
  flex-shrink: 0;
  top: 0;
`;

const HeaderBackground = styled.div`
  /* background-image: url("/HeaderBackground.png"); */
  /* background-attachment: initial; */
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  background: rgb(22, 22, 24, 0.1);
  position: absolute;
  inset: 0;
  z-index: -1;
`;

const Title = styled.div`
  @media screen and (max-width: 720px) {
    position: relative;
    bottom: 10px;
    margin: auto;
  }
`;

const MainTitle = styled(Link)`
  font-family: Kodchasan, serif;
  color: white;
  font-size: 23px;
  font-weight: 500;
  text-decoration: none;
`;

const MainSubTitle = styled.p`
  font-family: Kodchasan, serif;
  color: grey;
  font-size: 15px;
  font-weight: 500;
  margin-top: 4px;
  display: inline;
`;
