import styled from "styled-components";

export const Youtube = styled.iframe`
  margin-top: 15px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.4);
  padding: 5px;
  flex-shrink: 0;
  transition: 520ms;
  border-radius: 20px;
  :hover {
    transition: 520ms;
    box-shadow: 0px 0px 18px -5px rgba(255,255,255,.1);
    cursor: pointer;
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    margin-right: 0;
  }
`;
export const YoutubeBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 18px;
  transition: 0.4s ease 0s;
  :hover {
    letter-spacing: 4px;
  }

  @media screen and (max-width: 980px) {
    width: fit-content;
    font-size: 15px;
    flex-direction: column;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255, 0, 0, 0.3);
    height: 470px;
  }
`;

export const YoutubeBoxText = styled.div`
width: 100%;
margin: auto;
@media screen and (min-width: 1280px){
    width: 1000px;
`;
