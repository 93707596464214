import styled from "styled-components";
import { Link } from "react-router-dom";
import { Youtube } from "./Youtube";

export const IntrosPageList = styled.div`
  margin: 24px auto;
  display: flex;
  flex-wrap: wrap;
`;

export const IntrosItem = styled.div`
  font-family: Kodchasan, serif;
  color: white;
  background: #161618;
  width: fit-content;
  padding: 24px;
  flex-direction: column;
  display: flex;
  align-items: center;
  transition: 260ms;
  text-decoration: none;
  margin: 33px;
  font-size: 22px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px -5px rgba(255, 0, 0, 0.3);
`;

export const IntrosHeaderTitle = styled.p`
  font-weight: bold;
  margin: 0px 24px;
  text-align: center;
`;

export const IntroTextTitle = styled.p`
  font-weight: bold;
  margin-left: 20px;
  @media screen and (max-width: 980px) {
    padding: 10px;
    margin: auto;
  }
`;

export const IntroTextSubTitle = styled.p`
  margin-left: 20px;
  @media screen and (max-width: 980px) {
    margin: auto;
  }
`;

export const IntroBoxText = styled.div`
  line-height: 2;
  text-align: center;
  font-size: 18px;
  transition: 260ms;
  :hover {
    transition: 540ms;
    color: rgba(255, 255, 255, 1);
    text-background: #1a1a1a;
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.6),
      0 0 3px rgba(255, 255, 255, 0.5);
  }
`;

export const IntrosHeader = styled.div`
display: flex;
margin: auto;
align-items: center;
cursor: pointer;
height: 30px;
width: fit-content;
@media screen and (max-width: 980px){
  margin: auto;
  display: flex;
  text-align: center;
}
`;

export const IntrosHeaderContent = styled(Link)`
  font-family: Kodchasan, serif;
  color: grey;
  font-weight: bold;
  transition: 400ms;
  text-decoration: none;
  &:hover {
    color: #d3d3d3;
    cursor: pointer;
  }
`;

export const IntrosHeaderIntroBox = styled.div`
margin: auto;
`;
