import {
  PortfolioItem,
  PortfolioImg,
  PortfolioList,
} from "../Components/Portfolio";
import { Page } from "../Components/Page";

export const HomePage = () => {
  return (
    <Page>
      <PortfolioList>
        <PortfolioItem to="/intros">
          Intros
          <PortfolioImg src="https://i.imgur.com/Z013Sal.jpg" />
        </PortfolioItem>
        <PortfolioItem to="/video-edits">
          Video edits
          <PortfolioImg src="https://i.imgur.com/URmsdRe.jpg" />
        </PortfolioItem>
        <PortfolioItem to="/graphics">
          Graphics
          <PortfolioImg src="https://i.imgur.com/rUPi5wk.jpg" />
        </PortfolioItem>
      </PortfolioList>
    </Page>
  );
};
