import { Youtube, YoutubeBox, YoutubeBoxText } from "../Components/Youtube";
import {
  IntrosPageList,
  IntrosItem,
  IntrosHeaderTitle,
  IntroTextTitle,
  IntroTextSubTitle,
  IntroBoxText,
  IntrosHeader,
  IntrosHeaderContent,
  IntrosHeaderIntroBox,
} from "../Components/Intros";
import { Page } from "../Components/Page";
import { YoutubeCounter, YoutubeStatsBox } from "../Components/youtubeapi/youtubeCounter";

export const Intro = () => {
  return (
    <Page>
      <IntrosPageList>
        <IntrosItem>
        <YoutubeCounter/>
          <IntrosHeader>
            <IntrosHeaderContent to="/video-edits">
              video edits
            </IntrosHeaderContent>
            <IntrosHeaderIntroBox>
              <IntrosHeaderTitle>Intros</IntrosHeaderTitle>
            </IntrosHeaderIntroBox>
            <IntrosHeaderContent to="/graphics">graphics</IntrosHeaderContent>
          </IntrosHeader>
          <YoutubeBoxText>
            <YoutubeBox>
              <Youtube
                width="560"
                height="315"
                src="https://www.youtube.com/embed/T8zIv_ZNe4c"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></Youtube>
              <IntroBoxText>
                <IntroTextTitle>Want to know how I did it?</IntroTextTitle>
                <IntroTextSubTitle>
                  Stay tuned on this site, and wait for the link with tutorial!
                </IntroTextSubTitle>
              </IntroBoxText>
            </YoutubeBox>
            <YoutubeBox>
              <Youtube
                width="560"
                height="315"
                src="https://www.youtube.com/embed/8CafpJgRQok"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></Youtube>
              <IntroBoxText>
                <IntroTextTitle>Want to know how I did it?</IntroTextTitle>
                <IntroTextSubTitle>
                  Stay tuned on this site, and wait for the link with tutorial!
                </IntroTextSubTitle>
              </IntroBoxText>
            </YoutubeBox>
          </YoutubeBoxText>
        </IntrosItem>
      </IntrosPageList>
    </Page>
  );
};
