import styled from "styled-components";

export const AboutMeBoxProfile = styled.div`
  background: #161618;
  width: 600px;
  height: 150px;
  padding: 20px;
  display: flex;
  box-shadow: 0px 8px 20px -5px rgba(255, 0, 0, 0.1);
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  max-width: 100%;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 150px;
    height: 250px;
    justify-content: space-between;
    margin: 0px auto;
    align-items: center;
  }
`;

export const AboutMeProfile = styled.img`
  border-radius: 50%;
  display: flex;
  position: relative;
  width: 150px;
  box-shadow: 0px 0px 24px -5px black;
`;

export const ContactButton = styled.a`
display: flex;
position: relative;
align-items: center;
width: 100%;
`;

export const SocialMediaBox = styled.div`
  display: flex;
  margin: 10px auto;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 720px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2px auto;
  }
`;

export const AboutMeContactDiscord = styled.img`
  width: 100px;
  filter: drop-shadow(0px 3px 3px black);
`;
export const AboutMeContactFacebook = styled.img`
  width: 100px;
  filter: drop-shadow(0px 3px 3px black);
`;

export const AboutMeItem = styled.div`
  flex-direction: column;
  display: flex;
  margin: 24px auto;
  max-width: 100%;
`;
export const AboutMeList = styled.div`
  color: #999999;
  font-family: Kodchasan, serif;
  width: 500px;
  height: fit-content;
  display: flex;
  margin-top: 10px;
  font-size: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: normal;
  max-width: 100%;
`;
export const AboutMeListTitle = styled.div`
  color: white;
  font-family: Kodchasan, serif;
  width: 500px;
  height: fit-content;
  display: flex;
  margin: 10px auto;
  font-size: 20px;
  text-align: center;
  line-height: normal;
  max-width: 100%;
`;

export const AboutMeTextBox = styled.div`
  background: #161618;
  width: fit-content;
  height: fit-content;
  padding: 30px;
  display: flex;
  margin: 22px auto;
  font-size: 20px;
  box-shadow: 0px 8px 20px -5px rgba(255, 0, 0, 0.1);
  border-radius: 10px;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: calc(100% - 64px);

  @media screen and (max-width: 720px) {
    width: 80%;
  }
`;
