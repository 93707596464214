import { Page } from "../Components/Page";
import {
  AboutMeItem,
  AboutMeList,
  AboutMeTextBox,
  AboutMeListTitle,
  AboutMeProfile,
  AboutMeBoxProfile,
  AboutMeContactDiscord,
  ContactButton,
  AboutMeContactFacebook,
  SocialMediaBox,
} from "../Components/AboutMe";
import { Youtube } from "../Components/Youtube";

export const AboutMePage = () => {
  return (
    <Page>
      <AboutMeItem>
        <AboutMeBoxProfile>
          <AboutMeProfile src="./ProfileAboutMe.png" />
          <SocialMediaBox>
            <ContactButton href="https://instagram.com/fl0ta/" target="_blank">
              <AboutMeContactFacebook src="./instagram-logo.png" />
            </ContactButton>
          </SocialMediaBox>
        </AboutMeBoxProfile>
        <AboutMeTextBox>
          <AboutMeListTitle>
            Hi, I'm Jacob. A young man giving heart to work, especially with
            editing videos and now also with programming.
          </AboutMeListTitle>
          <AboutMeList>
            Editing has been my passion for over than two years, and I won't
            stop expanding my editing knowledge. I'm trying to notice every
            detail of edit in any video I'm watching anywhere. I'm a fast
            learner, and for sure, I can take up the most professional video for
            editing.
          </AboutMeList>
          <AboutMeList>
            I can't tell much about my journey with programming because I have
            just started it. I'm beginning with front-end, and I hope I will
            enhance my skills immensely fast.
          </AboutMeList>
        </AboutMeTextBox>
      </AboutMeItem>
    </Page>
  );
};
