import {
  IntroBoxText,
  IntrosItem,
  IntrosPageList,
  IntrosHeaderTitle,
  IntroTextSubTitle,
  IntroTextTitle,
  IntrosHeader,
  IntrosHeaderContent,
  IntrosHeaderIntroBox,
} from "../Components/Intros";

import { Page } from "../Components/Page";
import { Youtube, YoutubeBox, YoutubeBoxText } from "../Components/Youtube";
import { YoutubeCounter, YoutubeStatsBox } from "../Components/youtubeapi/youtubeCounter";

export const VideoEdit = () => {
  return (
    <Page>
      <IntrosPageList>
        <IntrosItem>
        <YoutubeCounter/>
          <IntrosHeader>
            <IntrosHeaderContent to="/intros">intros</IntrosHeaderContent>
            <IntrosHeaderIntroBox>
              <IntrosHeaderTitle>Video edits</IntrosHeaderTitle>
            </IntrosHeaderIntroBox>
            <IntrosHeaderContent to="/graphics">graphics</IntrosHeaderContent>
          </IntrosHeader>
          <YoutubeBoxText>
            <YoutubeBox>
              <Youtube
                width="560"
                height="315"
                src="https://www.youtube.com/embed/1yc_uG54Gys"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></Youtube>
              <IntroBoxText>
                <IntroTextTitle>Want to know how I did it?</IntroTextTitle>
                <IntroTextSubTitle>
                  Stay tuned on this site, and wait for the link with tutorial!
                </IntroTextSubTitle>
              </IntroBoxText>
            </YoutubeBox>
            <YoutubeBox>
              <Youtube
                width="560"
                height="315"
                src="https://www.youtube.com/embed/axdxeJ--XDQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></Youtube>
              <IntroBoxText>
                <IntroTextTitle>Want to know how I did it?</IntroTextTitle>
                <IntroTextSubTitle>
                  Stay tuned on this site, and wait for the link with tutorial!
                </IntroTextSubTitle>
              </IntroBoxText>
            </YoutubeBox>
          </YoutubeBoxText>
        </IntrosItem>
      </IntrosPageList>
    </Page>
  );
};
