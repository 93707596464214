import { Link } from "react-router-dom";
import styled from "styled-components";

export const PortfolioItem = styled(Link)`
  font-family: Kodchasan, serif;
  color: white;
  background: rgb(22, 22, 24, 0.4);
  width: fit-content;
  padding: 24px;
  flex-direction: column;
  display: flex;
  align-items: center;
  transition: 260ms;
  text-decoration: none;
  margin: 33px;
  font-size: 22px;
  border-radius: 10px;
  height: fit-content;
  box-shadow: 0px 8px 20px -5px rgba(255, 0, 0, 0.1);
  :hover {
    background: rgb(22, 22, 24);;
    box-shadow: 0px 8px 20px -5px rgba(255, 0, 0, 0.4);
    font-size: 25px;
    border-radius: 20px;
    text-shadow: 0px 5px 6px rgba(255, 0, 0, 0.4);
  }
`;

export const PortfolioList = styled.div`
  margin: 24px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const PortfolioImg = styled.img`
  width: 100%;
  margin-top: 16px;
  box-shadow: 0px 8px 18px -5px rgba(79, 192, 255, 0.06);
  transition: 260ms;
  @media screen and (min-width: 720px) {
    width: 500px;
    ${PortfolioItem}:hover & {
      box-shadow: 0px 8px 18px -5px rgba(79, 192, 255, 0.2);
      filter: brightness(150%);
      width: 520px;
    }
  
  }
`;
