import { Page } from "../Components/Page";
import {
  IntrosHeader,
  IntrosHeaderContent,
  IntrosHeaderIntroBox,
  IntrosHeaderTitle,
  IntrosItem,
  IntrosPageList,
} from "../Components/Intros";
import { YoutubeCounter } from "../Components/youtubeapi/youtubeCounter";
import { GraphicsBox, GraphicsImg, GraphicsItems } from "../Components/Graphics";

export const Graphics = () => {
  return (
    <Page>
      <IntrosPageList>
        <IntrosItem>
          <YoutubeCounter />
          <IntrosHeader>
            <IntrosHeaderContent to="/video-edits">
              video edits
            </IntrosHeaderContent>
            <IntrosHeaderIntroBox>
              <IntrosHeaderTitle>Graphics</IntrosHeaderTitle>
            </IntrosHeaderIntroBox>
            <IntrosHeaderContent to="/intros">Intros</IntrosHeaderContent>
          </IntrosHeader>
          <GraphicsItems>
          <GraphicsBox>
            <GraphicsImg src="https://i.imgur.com/qR9B7RZ.jpg" />
          </GraphicsBox>
          <GraphicsBox>
            <GraphicsImg src="https://i.imgur.com/EfB44gH.png" />
          </GraphicsBox>
          <GraphicsBox>
            <GraphicsImg src="https://i.imgur.com/s1Tx1NY.png" />
          </GraphicsBox>
          </GraphicsItems>
        </IntrosItem>
      </IntrosPageList>
    </Page>
  );
};
