import styled from "styled-components";
import { PageHeader } from "./Header";

const Container = styled.div`
  background-color: #27272a;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  `;

const PageContent = styled.div`
  background: linear-gradient(290deg, rgba(150,40,235,1) 0%, rgba(88,8,251,1) 100%);
  flex-grow: 1;
  display: flex;
  background-color: #1e1e21;
background-attachment: fixed;
background-size: cover;
`;

export const Page = (props) => {
  return (
    <Container>
      <PageHeader />
      <PageContent>
        {props.children}
      </PageContent>
    </Container>
  );
};
