import React, { useEffect, useState } from "react";
import { config } from "./config";
import styled from "styled-components";

export const YoutubeStatsBox = styled.div`
  display: flex;
  font-size: 11px;
  margin-bottom: 10px;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  flex-direction: column;
  @media screen and (min-width: 980px) {
    flex-direction: row;
    display: flex;
    width: 100%;
    font-size: 15px;
    border-right: 2px solid rgba(255, 0, 0, 0.3);
    border-left: 2px solid rgba(255, 0, 0, 0.3);
    justify-content: space-between;
    padding: 0px 5px;
  }
`;

export const YoutubeCounter = () => {
  const [subscriberCount, setSusbscriberCount] = useState();
  const [viewCount, setViewCount] = useState();
  useEffect(() => {
    const { api_key, channel_id } = config;
    const apiCall = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channel_id}&key=${api_key}`;
    fetch(apiCall)
      .then((result) => result.json())
      .then((data) => {
        const count = data.items[0].statistics.subscriberCount;
        const viewCount = data.items[0].statistics.viewCount;
        setSusbscriberCount(count);
        setViewCount(viewCount);
        console.log(data);
      });
  });

  return (
    <YoutubeStatsBox>
      <p>Views counter: {viewCount}</p>
      <p>Subs counter: {subscriberCount}</p>
    </YoutubeStatsBox>
  );
};
